import { createStore } from 'vuex'
import packageData from './../../package.json'

import auth from './auth'
import company from './company'
import entries from './entries'

export default createStore({
  state: {
    error: null,
    appVersion: packageData.version,
  },
  mutations: {
    setError(state, error) {
      console.log(error)
      state.error = error
    },
    clearError(state) {
      state.error = null
    },
    clearInfo(state) {
      state.error = null
    }
  },
  getters: {
    error: s => s.error
  },
  modules: {
    auth, company, entries
  }
})
