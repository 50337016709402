import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, startAfter, serverTimestamp, writeBatch, where } from "firebase/firestore"
import { getAuth } from 'firebase/auth'

export default {
  state: {
    entries: null,
    lastFetchedEntry: null,
    allEntriesFetched: false,
    entriesReset: 0,
    entriesSearchStr: '',
    filters: {
      showIncoming: true,
      showOutgoing: true,
      showOthers: true,
      showChain: null
    }
  },
  mutations: {
    setEntry(state, entry) {
      if (!state.entries) { state.entries = {} }
      if (entry && entry.id) {
        state.entries[entry.id] = entry
      }
    },
    updateEntry(state, entry) {
      if (!state.entries) { state.entries = {} }
      if (entry && entry.id) {
        for (const field of Object.keys(entry)) {
          state.entries[entry.id][field] = entry[field]
        }
      }
    },
    setLastFetchedEntry(state, entry) {
      state.lastFetchedEntry = entry
    },
    setAllEntriesFetched(state, value) {
      state.allEntriesFetched = value
    },
    setEntriesSearchStr(state, value) {
      state.entriesSearchStr = value
    },
    resetEntries(state) {
      state.entriesReset = Date.now()
    },
    deleteEntryFromStore(state, entryId) {
      if (entryId && state.entries && state.entries[entryId]) {
        delete state.entries[entryId]
      }
    },
    setShowIncoming(state, value) {
      state.filters.showIncoming = value
    },
    setShowOutgoing(state, value) {
      state.filters.showOutgoing = value
    },
    setShowOthers(state, value) {
      state.filters.showOthers = value
    },
    setShowChain(state, value) {
      state.filters.showChain = value
    },
    clearFilters(state) {
      state.filters = {
        showIncoming: true,
        showOutgoing: true,
        showOthers: true,
        showChain: null
      }
    },
    clearInfo(state) {
      state.entries = null
      state.lastFetchedEntry = null
      state.allEntriesFetched = false
      state.entriesSearchStr = ''
      state.filters = {
        showIncoming: true,
        showOutgoing: true,
        showOthers: true,
        showChain: null
      }
    },
  },
  actions: {
    async fetchEntries({ commit, getters }) {
      if (getters.allEntriesFetched) { return }

      if (!getters.company || !getters.company.id) {
        commit('setError', 'Error')
        return
      }
      const maxLimit = 10

      try {
        const uid = getAuth()?.currentUser?.uid || null;

        const ref = collection(getFirestore(), 'apps/status/entries')
        const queries = [orderBy('date', 'desc'), where('userId', '==', uid), limit(maxLimit)]

        const lastFetchedEntry = await getters.lastFetchedEntry
        if (lastFetchedEntry) { queries.push(startAfter(lastFetchedEntry)) }

        const q = query(ref, ...queries)
        const entries = await getDocs(q)
        if (!entries.empty) {
          for (const entry of entries.docs) {
            const entryData = entry.data()
            entryData.id = entry.id

            if (entryData.timestamp && entryData.timestamp.toDate) { entryData.timestamp = entryData.timestamp.toDate() }
            if (entryData.date && entryData.date.toDate) { entryData.date = entryData.date.toDate() }

            await commit('setEntry', entryData)
          }

          await commit('setLastFetchedEntry', entries.docs[entries.docs.length - 1])
        }

        if (entries.empty || entries.size < maxLimit) {
          await commit('setAllEntriesFetched', true)
        }

        commit('resetEntries')
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async fetchEntry({ commit }, entryId) {
      if (!entryId) {
        commit('setError', 'Не указан entryId')
        return
      }

      try {
        const ref = doc(getFirestore(), `apps/status/entries/${entryId}`)
        const entry = await getDoc(ref)

        if (entry.exists()) {
          const entryData = entry.data()
          entryData.id = entry.id

          if (entryData.timestamp && entryData.timestamp.toDate) { entryData.timestamp = entryData.timestamp.toDate() }
          if (entryData.date && entryData.date.toDate) { entryData.date = entryData.date.toDate() }

          await commit('setEntry', entryData)
        }
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async deleteEntry({ commit }, entryId) {
      if (!entryId) {
        commit('setError', 'Ну указан идентификатор записи')
        return
      }

      try {
        const ref = doc(getFirestore(), `apps/status/entries/${entryId}`)

        await deleteDoc(ref)

        await commit('deleteEntryFromStore', entryId)
        commit('resetEntries')

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async createEntryId({ commit }) {
      try {
        const entryId = doc(collection(getFirestore(), 'apps/status/entries')).id
        return entryId
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async saveEntry({ commit, getters }, { data, entryId }) {
      if (!data || !entryId || !data.companyId || !data.chainKey || Object.keys(data).length <= 2) {
        commit('setError', 'Ошибка')
        return
      }

      try {
        const uid = getAuth()?.currentUser?.uid || null;

        data.userId = uid
        data.timestamp = serverTimestamp()

        if (data.id) {
          delete data.id
        }

        const batch = writeBatch(getFirestore())

        const entryRef = doc(getFirestore(), `apps/status/entries/${entryId}`)

        if (!getters.entries || !getters.entries[entryId]) {
          batch.set(entryRef, data)
        } else {
          batch.update(entryRef, data)
        }

        await batch.commit()

        data.id = entryId

        if (!getters.entries || !getters.entries[entryId]) {
          commit('setEntry', data)
        } else {
          commit('updateEntry', data)
        }

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    entries: s => s.entries,
    lastFetchedEntry: s => s.lastFetchedEntry,
    allEntriesFetched: s => s.allEntriesFetched,
    entriesReset: s => s.entriesReset,
    entriesSearchStr: s => s.entriesSearchStr,
    filters: s => s.filters,
    entriesArr: (s, getters) => {
      if (getters.entriesReset) {
        //
      }

      let arr = []

      if (!getters.entries) { return arr }

      const entriesIds = Object.keys(getters.entries)
      for (const entryId of entriesIds) {
        arr.push(getters.entries[entryId])
      }

      if (!getters.filters.showIncoming) {
        arr = arr.filter(entry => entry.type !== 'incoming')
      }

      if (!getters.filters.showOutgoing) {
        arr = arr.filter(entry => entry.type !== 'outgoing')
      }

      if (!getters.filters.showOthers) {
        arr = arr.filter(entry => entry.type !== 'other')
      }

      if (getters.filters.showChain) {
        if (getters.entries && getters.entries[getters.filters.showChain]) {
          const showChainChainKey = getters.entries[getters.filters.showChain].chainKey
          arr = arr.filter(entry => (entry.id === showChainChainKey || entry.chainKey === showChainChainKey))
        }
      }

      if (getters.entriesSearchStr && getters.entriesSearchStr.length) {
        arr = arr.filter(entry => {
          for (const mailKey of Object.keys(entry.mail)) {
            if (entry.mail[mailKey] && (entry.mail[mailKey] + '').toLowerCase().includes(getters.entriesSearchStr.toLowerCase())) { return true }
          }

          for (const link of entry.links) {
            if (link.text && link.text.toLowerCase().includes(getters.entriesSearchStr.toLowerCase())) { return true }
            if (link.url && link.url.toLowerCase().includes(getters.entriesSearchStr.toLowerCase())) { return true }
          }

          for (const inv of entry.inventory) {
            if (inv.comment && inv.comment.toLowerCase().includes(getters.entriesSearchStr.toLowerCase())) { return true }
            if (inv.name && inv.name.toLowerCase().includes(getters.entriesSearchStr.toLowerCase())) { return true }
            if (inv.url && inv.url.toLowerCase().includes(getters.entriesSearchStr.toLowerCase())) { return true }
            if (inv.urlText && inv.urlText.toLowerCase().includes(getters.entriesSearchStr.toLowerCase())) { return true }
          }

          return (
            (entry.comment && entry.comment.toLowerCase().includes(getters.entriesSearchStr.toLowerCase()))
            || (entry.communicationType && entry.communicationType.toLowerCase().includes(getters.entriesSearchStr.toLowerCase()))
            || (entry.num && entry.num.toLowerCase().includes(getters.entriesSearchStr.toLowerCase()))
            || (entry.recipient && entry.recipient.toLowerCase().includes(getters.entriesSearchStr.toLowerCase()))
          )
        })
      }

      arr.sort((a, b) => {
        if (+a.date > +b.date) { return -1 }
        if (+a.date < +b.date) { return 1 }
        return 0
      })

      return arr
    }
  }
}
