export default {
  name: 'intersection',
  mounted(el, binding) {
    const options = {
      rootMargin: '200px',
      threshold: 0.25
    }

    const callback = (entries) => {
      if (entries[0].isIntersecting) {
        binding.value()
      }
    }

    const observer = new IntersectionObserver(callback, options)
    observer.observe(el)
  }
}
