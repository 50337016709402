<template>
  <div id="app" class="min-h-screen min-w-min h-auto inset-0 bg-blue-100">
    <div
      v-if="!menuIsOpen && user && !$route.meta.hideNavbar"
      class="fixed top-2 cursor-pointer left-3"
      @click="menuIsOpen = true"
    >
      <img
        src="../public/img/app-images/menu_18dp_gray50.svg"
        class="h-7 w-7"
      >
    </div>

    <Drawer
      v-if="user && !$route.meta.hideNavbar"
      :email="user.email"
      :menuIsOpen="menuIsOpen"
      @close-menu="menuIsOpen = false"
    />

    <div class="ml-12 mr-4 pt-2 pb-5">
      <router-view />
    </div>

    <AppNotifications />
  </div>
</template>

<script>
import AppNotifications from '@/components/UI/AppNotifications'
import useNotifications from '@/composables/useNotifications'
import Drawer from '@/components/navigation/Drawer'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export default {
  components: {
    AppNotifications, Drawer
  },
  setup () {
    const { toastify } = useNotifications()
    return { toastify }
  },
  data: () => ({
    menuIsOpen: false,
    user: null
  }),
  computed: {
    error () {
      return this.$store.getters.error
    }
  },
  watch: {
    error (fbError) {
      if(fbError) {
        this.toastify.error(fbError)
        this.$store.commit('clearError')
      }
    }
  },
  async created() {
    const auth = getAuth()
    onAuthStateChanged(auth, user => {
      if (user) {
        this.user = user
      } else {
        this.user = null
      }
    })
  }
}
</script>


<style>
input[type='number'] {
  -moz-appearance:textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
