import { createApp } from 'vue'

import App from './App.vue'

import router from './router'
import store from './store'

import "tailwindcss/tailwind.css"

import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, inMemoryPersistence, browserLocalPersistence, setPersistence } from 'firebase/auth'

import VIntersection from '@/directives/VIntersection'
import { setAppStartPageCookie } from '../../settings/cookies'
import { APP_ID } from './services/settings'

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDKRtzOjg042IyLXth6CKCpgJB00HvmLCY",
  authDomain: "sheremetiev-pro-d395c.firebaseapp.com",
  projectId: "sheremetiev-pro-d395c",
  storageBucket: "sheremetiev-pro-d395c.appspot.com",
  messagingSenderId: "482828223207",
  appId: "1:482828223207:web:55cadaac9f936c5cc65842"
})

let app

const auth = getAuth(firebaseApp)

if (process.env.NODE_ENV === 'production') {
  setPersistence(auth, inMemoryPersistence)
  setAppStartPageCookie(APP_ID)
} else {
  setPersistence(auth, browserLocalPersistence)
}

onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App)
    app.use(router)
    app.use(store)

    app.directive('intersection', VIntersection)

    app.mount('#app')
  }
})
