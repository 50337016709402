import { reactive } from 'vue'

const notifications = reactive([])
const maxNotifications = 7

const addNotification = (message, type = 'info', { timeout = 5000, eternal = false, icon = null, remove = [] }) => {
  for (const notificationId of remove) {
    removeNotification(notificationId)
  }

  clearMaxNotifications()

  const id = (Date.now() + Math.random())

  if (!icon) { icon = type }

  const notification = {
    id,
    message,
    eternal,
    icon,
    type,
    timeout: null,
    timerId: null
  }

  if (!eternal) {
    if (!Number.isInteger(timeout)) {
      timeout = null
    }

    if (timeout) {
      notification.timerId = setTimeout(() => removeNotification(id), timeout)
    }

    notification.timeout === timeout
  }

  notifications.push(notification)

  return id
}

const replaceNotification = (id, message, type = 'info', { timeout = 5000, eternal = false, icon = null, remove = [] }) => {
  for (const notificationId of remove) {
    if (notificationId !== id) {
      removeNotification(notificationId)
    }
  }

  if (!icon) { icon = type }

  const index = notifications.findIndex(item => item.id === id)
  if (index < 0) { return null }

  if (notifications[index].timerId) {
    clearInterval(notifications[index].timerId)
    notifications[index].timerId = null
  }

  notifications[index].type = type
  notifications[index].message = message
  notifications[index].icon = icon
  notifications[index].timeout = timeout
  notifications[index].eternal = eternal

  if (!eternal) {
    if (!Number.isInteger(timeout)) {
      timeout = null
    }

    if (timeout) {
      notifications[index].timerId = setTimeout(() => removeNotification(id), timeout)
    }

    notifications[index].timeout === timeout
  }

  return notifications[index].id
}

const removeNotification = (id) => {
  if (id) {
    const index = notifications.findIndex(item => item.id === id)
    if (index >= 0) {
      notifications.splice(index, 1)
    }
  }
}

const clearNotifications = () => {
  notifications.splice(0, notifications.length)
}

const clearMaxNotifications = () => {
  if (notifications.length >= maxNotifications) {
    for (const notification of notifications) {
      if (!notification.eternal) {
        removeNotification(notification.id)
        return
      }
    }
  }
}

const toastify = {
  info: (message, options = {}) => addNotification(message, 'info', options),
  success: (message, options = {}) => addNotification(message, 'success', options),
  warning: (message, options = {}) => addNotification(message, 'warning', options),
  error: (message, options = {}) => addNotification(message, 'error', options),
  remove: (id) => removeNotification(id),
  replace: (id, message, type = 'info', options = {}) => {
    if (!id) {
      return addNotification(message, type, options)
    }

    const index = notifications.findIndex(item => item.id === id)
    if (index < 0) {
      return addNotification(message, type, options)
    }
    return replaceNotification(id, message, type, options)
  },
  clearNotifications: () => {
    return clearNotifications()
  }
}

export default function useNotifications() {
  return {
    notifications, toastify
  }
}
