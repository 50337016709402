import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'
import { setPageTitle } from '../../../settings/page-meta'
import { APP_NAME } from '../services/settings'

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    meta: { title: 'Авторизация', hideNavbar: true },
    component: () => import('./../views/Auth.vue')
  },
  {
    path: "/",
    name: "Entries",
    meta: { auth: true, toTop: true, title: 'Записи' },
    component: () => import('../views/Entries.vue')
  },
  {
    path: '/entry/:id',
    name: 'Entry',
    props: true,
    meta: { auth: true, toTop: true, title: 'Запись' },
    component: () => import('./../views/Entry.vue')
  },
  {
    path: '/view/:id',
    name: 'EntryView',
    props: true,
    meta: { auth: true, toTop: true, title: 'Запись' },
    component: () => import('./../views/EntryView.vue')
  },
  {
    path: "/company",
    name: "Company",
    meta: { auth: true, toTop: true, title: 'Компания' },
    component: () => import('../views/Company.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {}
    if (to.meta.toTop) {
      scroll.top = 0
      scroll.behavior = 'smooth'
    }
    return scroll
  }
})

router.beforeEach((to, from, next) => {
  setPageTitle(to.meta.title, APP_NAME)

  const currentUser = getAuth().currentUser
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && !currentUser) {
    if (to.name !== 'Auth') {
      next({ name: 'Auth' })
      return
    }
  }

  next()
})

export default router;
