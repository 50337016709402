<template>
  <div class="notifications">
    <TransitionGroup name="notification">
      <div
        v-for="notification of notifications"
        :key="notification.id"
        class="notification"
        :class="{
          'notification-info': !notification.type || notification.type === 'info',
          'notification-success': notification.type === 'success',
          'notification-warning': notification.type === 'warning',
          'notification-error': notification.type === 'error',
        }"
      >
        <div v-if="notification.icon">
          <svg v-if="notification.icon === 'dangerous'" class="notification-icon" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" viewBox="0 0 20 20"><g><path d="M0,0h20v20H0V0z" fill="none"/></g><g><path d="M12.27,3H7.73c-0.4,0-0.78,0.16-1.06,0.44L3.44,6.67C3.16,6.95,3,7.34,3,7.73v4.53c0,0.4,0.16,0.78,0.44,1.06l3.23,3.23 C6.95,16.84,7.34,17,7.73,17h4.53c0.4,0,0.78-0.16,1.06-0.44l3.23-3.23c0.28-0.28,0.44-0.66,0.44-1.06V7.73 c0-0.4-0.16-0.78-0.44-1.06l-3.23-3.23C13.05,3.16,12.66,3,12.27,3z M12.75,12.75L12.75,12.75c-0.29,0.29-0.77,0.29-1.06,0 L10,11.06l-1.69,1.69c-0.29,0.29-0.77,0.29-1.06,0l0,0c-0.29-0.29-0.29-0.77,0-1.06L8.94,10L7.25,8.31c-0.29-0.29-0.29-0.77,0-1.06 l0,0c0.29-0.29,0.77-0.29,1.06,0L10,8.94l1.69-1.69c0.29-0.29,0.77-0.29,1.06,0l0,0c0.29,0.29,0.29,0.77,0,1.06L11.06,10l1.69,1.69 C13.04,11.98,13.04,12.46,12.75,12.75z"/></g></svg>
          <svg v-else-if="notification.icon === 'info'" class="notification-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z"/></svg>
          <svg v-else-if="notification.icon === 'warning'" class="notification-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"/></svg>
          <svg v-else-if="notification.icon === 'success'" class="notification-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"/></svg>

          <img
            v-else
            :src="getImgUrl(notification.icon)"
            :key="notification.icon + '-' + notification.id"
            :id="'notificationIcon-' + notification.id"
            class="notification-icon"
            @error="imgError('notificationIcon-' + notification.id)"
          >
        </div>

        <div class="notification-text-container">
          <div class="notification-text">
            {{ notification.message }}
          </div>
        </div>

        <Transition name="fade">
          <div v-if="!notification.eternal">
            <svg
              class="notification-close-button"
              :key="notification.eternal + '-' + notification.id"
              @click="toastify.remove(notification.id)"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/>
            </svg>
          </div>
        </Transition>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import useNotifications from '@/composables/useNotifications'

export default {
  setup() {
    const { notifications, toastify } = useNotifications()
    return { notifications, toastify }
  },
  methods: {
    getImgUrl(icon) {
      if (icon) { return require(`@/assets/img/icons/${icon}.svg`) }
      return null
    },
    imgError(id) {
      const img = document.getElementById(id)
      img.style.display = 'none'
    }
  }
}
</script>

<style scoped>

  @media (max-width: 480px) {
    .notifications {
      width: 100%;
    }

    .notification {
      border-radius: 0.375rem 0.375rem 0 0;
    }
  }

  @media (min-width: 480px) {
    .notifications {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 25px;
    }

    .notification {
      width: 350px;
      border-radius: 0.375rem;
    }
  }

  .notifications {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
    overflow-y: scroll;
    max-height: 100vh;
    padding-top: 20px;
  }

  .notification {
    position: relative;
    display: flex;
    max-height: 120px;
    box-shadow: 2px 2px 2px 2px rbga(0, 0, 0.5);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left:10px;
    margin-top: 5px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    -webkit-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
  }

  .notification-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    -webkit-animation: fadeIn ease-in .2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeIn ease-in .2s; /* Firefox < 16 */
      -ms-animation: fadeIn ease-in .2s; /* Internet Explorer */
       -o-animation: fadeIn ease-in .2s; /* Opera < 12.1 */
          animation: fadeIn ease-in .2s;
  }

  .notification-text-container {
    overflow-y: scroll;
    width: fit-content;
    margin-left: 25px;
    margin-right: 17px;
    padding-right: 8px;
  }

  .notification-text {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    word-break: break-word;
  }

  .notification-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .notification-close-button:hover {
    opacity: 0.75;
  }

  .notification-enter-active,
  .notification-leave-active {
    transition: all .5s ease;
  }

  .notification-enter-from,
  .notification-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }

  .notifications::-webkit-scrollbar,
  .notification-text-container::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  @media (prefers-color-scheme: dark) {
    .notification-info {
      background: #60a5fa;
    }

    .notification-success {
      background: #34d399;
    }

    .notification-warning {
      background: #fb923c;
    }

    .notification-error {
      background: #f87171;
    }

    .notification-text {
      color: #374151;
    }

    .notification-icon {
      fill: #374151;
    }

    .notification-close-button {
      fill: #374151;
    }
  }

  @media (prefers-color-scheme: light) {
    .notification-info {
      background: #93c5fd;
    }

    .notification-success {
      background: #6ee7b7;
    }

    .notification-warning {
      background: #fdba74;
    }

    .notification-error {
      background: #fca5a5;
    }

    .notification-text {
      color: #4b5563;
    }

    .notification-icon {
      fill: #4b5563;
    }

    .notification-close-button {
      fill: #4b5563;
    }
  }
</style>
