import { getFirestore, getDocs, collection, doc, query, writeBatch, where } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

export default {
  state: {
    company: null
  },
  mutations: {
    setCompany(state, company = {}) {
      state.company = company
    },
    clearInfo(state) {
      state.company = null
    },
  },
  actions: {
    async fetchCompany({ commit }) {
      try {
        const uid = getAuth()?.currentUser?.uid || null;

        const ref = collection(getFirestore(), 'apps/status/companies')
        const q = query(ref, where('uid', '==', uid))
        const answer = await getDocs(q)
        if (answer && !answer.empty) {
          for (const company of answer.docs) {
            const data = company.data()
            data.id = company.id
            await commit('setCompany', data)
          }
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async saveCompany({ commit }, { newData, oldData }) {
      if (!newData || !oldData) {
        commit('setError', 'Нет данных для сохранения')
        return false
      }

      const uid = getAuth()?.currentUser?.uid || null;

      let data = {}

      if (!oldData.id) {
        data = newData
      } else {
        for (const field of Object.keys(newData)) {
          if (newData[field] !== oldData[field]) { data[field] = newData[field] }
        }
      }

      if (!Object.keys(data).length) {
        commit('setError', 'Нет данных для сохранения')
        return false
      }

      try {
        let companyId
        if (oldData.id) {
          companyId = oldData.id
        } else {
          companyId = doc(collection(getFirestore(), 'apps/status/companies')).id
        }

        data.uid = uid

        const batch = writeBatch(getFirestore())

        const companyRef = doc(getFirestore(), `apps/status/companies/${companyId}`)

        if (oldData.id) {
          batch.update(companyRef, data)
        } else {
          batch.set(companyRef, data)
        }

        await batch.commit()

        newData.id = companyId
        await commit('setCompany', newData)
        return true
      } catch (error) {
        commit('setError', error)
        return false
      }
    }
  },
  getters: {
    company: s => s.company
  }
}
