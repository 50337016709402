<template>
  <div>
    <div
      class="bg-blue-400 z-30 pt-4 pb-2 px-6 fixed inset-0 w-60 overflow-y-auto flex flex-col justify-between transform transition-transform duration-300 ease-in"
      :class="{'-translate-x-60': !menuIsOpen, 'translate-x-0': menuIsOpen}"
    >
      <div>
        <div class="text-gray-600 text-xl transition-opacity mb-1 h-full">
          <div class="border-b border-blue-200 flex flex-col overflow-hidden mb-3">
            <div class="flex overflow-hidden mb-2">
              <div class="text-sm text-gray-500 truncate">
              {{ email }}
            </div>
            </div>
          </div>

          <div
            v-for="link in links"
            :key="link.name"
            class="mb-2"
          >
            <router-link
              class="hover:opacity-70"
              :class="{'cursor-default': $route.name === link.name}"
              active-class="opacity-50"
              :to="{ name: link.name }"
              :exact="link.exact"
            >
              <span @click="closeMenu()">{{ link.title }}</span>
            </router-link>
          </div>

          <span
            class="cursor-pointer hover:opacity-70"
            @click.prevent="logout()"
          >
            Выйти
          </span>
        </div>
      </div>

      <div class="text-xs mt-4 sm:mt-2">
        <p
          class="mt-2 text-gray-600 text-center"
        >
          v{{ appVersion }}
        </p>
      </div>
    </div>

    <div
      v-if="menuIsOpen"
      class="z-20 fixed inset-0 bg-blue-300 opacity-20"
      @click="closeMenu()"
    />
  </div>
</template>

<script>
import { getLogoutURL } from '../../../../settings/auth'
import { APP_ID } from '../../services/settings'

export default {
  name: 'DrawerNav',
  props: {
    menuIsOpen: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    links: [
      { title: 'Регистрация', name: 'Entries' },
      { title: 'Компания', name: 'Company' },
    ]
  }),
  computed: {
    appVersion () {
      return this.$store.state.appVersion
    }
  },
  methods: {
    closeMenu() {
      this.$emit('close-menu')
    },
    async logout() {
      this.closeMenu()
      window.location.href = getLogoutURL(APP_ID) + '&authMessage=' + encodeURI('Успешно')
    }
  }
}
</script>
